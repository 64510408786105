@import '../_sass/_variables';
@import '../_sass/_colors';

input[type="text"] {
    background-color: inherit;
    color: $beige;
    font-size: $text-size;
    font-family: "Lato", sans-serif;

    border: 2px solid $xdark-orange;

    margin-top: 0.8rem;
    margin-left: $text-size;
}
