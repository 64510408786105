@import '../_sass/_variables';
@import '../_sass/_colors';

.arrow {
    height: $text-size;
    margin-right: 1rem;
}

.open-arrow {
    transform: rotate(180deg) translateY(-20%);
}
.closed-arrow {
    transform: rotate(90deg) translateX(20%);
}


.closed-subnotes {
    display: none;
}


input[type=checkbox] {
    width: $text-size;
    height: $text-size;
    margin-right: 0.6rem;

    position: relative;
    top: calc(#{$text-size} / 8);

    accent-color: $orange;
    filter: invert(100%);
}
