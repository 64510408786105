@import '_sass/_colors';
@import '_sass/_variables';

* {
    padding: 0;
    border: 0;
    margin: 0;
}

body {
    background-color: $black;
    color: $beige;
    font-family: "Lato", sans-serif;

    width: unquote("min(95vw, 60rem)");
    margin: auto;
}

h1 {
    color: $orange;
    font-size: $title-size;
}

h2 {
    display: inline;
    font-size: $text-size;
}

form {
    display: inline-block;
}

/* Converts solid black images to solid beige */
img {
    filter: invert(100%) brightness(70%) sepia(100%);
}
