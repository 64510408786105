@import '../_sass/_colors';
@import '../_sass/_variables';

#submission-box {
    align-items: center;

    margin-top: 10rem;

    & button {
        margin: auto;
        padding: calc($text-size / 2);
        padding-left: $text-size;
        padding-right: $text-size;
        border: 5px $xdark-orange solid;
        border-radius: 1rem;

        background-color: inherit;
        color: $beige;
        font-size: $text-size;

        transition: ease-in-out 0.1s;
        &:hover {
            background-color: $dark-blue;
        }
    }
}

.sb-visible {
    display: flex;
}
.sb-invisible {
    display: none;
}
